import httpClient from "@Js/httpClient";

export default {
    methods: {
        ajax_basic: function (
            data,
            url,
            method,
            headers,
            progress_key,
            cb,
            show_notify_errors
        ) {
            return httpClient({
                data,
                url,
                method,
                headers,
                progress_key,
                cb,
                show_notify_errors,
            }).then((response) => {
                return response;
            });
            ;
        },
    },
};
