import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import {
    State as commonState,
    Mutations as commonMutations,
    Actions as commonActions,
    Getters as commonGetters,
} from "@Js/mixins/vuexCommon";

//import { Actions as notifyActions } from "@Js/mixins/vuexNotify";

export default new Vuex.Store({
    state: {
        ...commonState(),
        all_metals_latest: [],
        symbols: [],
        symbols_history: {},
        latest_cbr_rates: [],
        groups: [],
        related_symbol_codes: [],
    },
    mutations: {
        ...commonMutations(),
    },
    actions: {
        //...notifyActions(),
        ...commonActions(),
        fetch_symbol_history({ dispatch, commit, state, getters }, code) {
            if (getters.is_loaded("fetch_symbol_history" + code)) {
                return Promise.resolve();
            }
            return Promise.all([dispatch("init_fetch")]).then(() => {
                return dispatch("ajax_basic", {
                    url: "/api/api-metals/history/" + code,
                    method: "GET",
                    progress_key: "fetch_symbol_history" + code,
                    cb: (response) => {
                        console.log(
                            JSON.parse(JSON.stringify(response.data.props))
                        );
                        commit("toState", {
                            ["symbols_history." + code]: response.data.props,
                        });
                    },
                });
            });
        },
        init_fetch({ dispatch, commit, state, getters }, payload) {
            if (getters.is_loaded("init_fetch")) {
                return Promise.resolve();
            }
            return dispatch("ajax_basic", {
                url: "/api/api-metals/common_data",
                method: "GET",
                progress_key: "init_fetch",
                cb: (response) => {
                    console.log(
                        JSON.parse(JSON.stringify(response.data.props))
                    );
                    commit("toState", {
                        related_symbol_codes:
                            response.data.props.related_symbol_codes,
                        groups: response.data.props.groups,
                        latest_cbr_rates: response.data.props.latest_cbr_rates,
                        symbols: response.data.props.all_symbols,
                        all_metals_latest:
                            response.data.props.all_metals_latest,
                    });
                },
            });
        },
    },
    getters: {
        ...commonGetters(),
    },
});
