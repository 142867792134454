<template>
    <div class="col-12 col-xs-6 col-md-4 col-xl-2" v-if="price_visible != 0">
        <div class="grid_item">
            <div class="chart" style="height: 100px">
                <ChartForGridItem :chartData="chartData" />
            </div>
            <div class="not_chart">
                <div class="link_wr">
                    <a :href="href" @click.prevent="goto">{{ symbol_name }}, <small>{{units_ru_short}}</small></a>
                </div>
                <div>
                    <strong style="white-space: nowrap">
                        {{ price_visible }}
                        <span class="dynamic" :class="dynamic_class">{{ dynamic }}</span></strong
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ChartForGridItem from './chart_for_grid_item.vue';
import { mapGetters, mapState } from 'vuex';
export default {
    components: {
        ChartForGridItem,
    },
    props: {
        elem: {
            type: Object,
            default: () => ({}), // Default value for elem as an empty object
        },
        active_group: {
            type: Object,
            default: () => ({}), // Default value for active_group as an empty object
        },
        open_new_tab: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            units_ru: [
                { em: 'g', short_ru: 'гр' },
                { em: 'oz', short_ru: 'унц.' },
                { em: 'toz', short_ru: 'т. унц.' },
                { em: 'kg', short_ru: 'кг' },
                { em: 'mt', short_ru: 'тн' },
            ],
        };
    },
    computed: {
        ...mapState(['symbols']),
        symbol() {
            return this.symbols.find((sy) => sy.id === this.elem.symbol_id);
        },
        symbol_name() {
            return this.symbol.name_ru || this.symbol.name;
        },
        dynamic() {
            //return '-2%';
            return this.elem.diff_operator + this.elem.diff + '%';
        },
        dynamic_class() {
            switch (this.elem.diff_operator) {
                case '+':
                    return 'dynamic_plus';
                case '-':
                    return 'dynamic_minus';
            }
        },
        units_ru_short(){
            return this.units_ru.find((el) => el.em === this.unit).short_ru;
        },
        currency_code() {
            switch (this.elem.current.cur) {
                case 1:
                    return 'USD';
                case 2:
                    return 'RUB';
            }
        },
        unit() {
            return this.symbol.visible_unit;
        },
        price_visible() {
            let price = this.elem.current.em_prices[this.unit];
            if (price < 0.01) {
                return 0;
            } else {
                return price.toLocaleString('ru-RU', {
                    style: 'currency',
                    currency: this.currency_code,
                    minimumFractionDigits: price < 500 ? 2 : 0,
                    maximumFractionDigits: price < 500 ? 2 : 0,
                });
            }
        },
        chartData() {
            return {
                labels: this.elem.dynamic_prices,
                datasets: [
                    {
                        backgroundColor: '#0d6efd',
                        data: this.elem.dynamic_prices,
                        cubicInterpolationMode: 'monotone',
                        fill: false,
                        borderColor: '#0d6efd',
                        borderWidth: 1,
                        radius: 1,
                    },
                ],
            };
        },
        routeObject() {
            return {
                name: 'symbol_page',
                params: { code: this.symbol.code },
            };
        },
        href() {
            return this.$router.resolve(this.routeObject).href;
        },
    },
    methods: {
        goto() {
            // Navigate to the route
            if (this.open_new_tab) {
                // Open in a new tab
                console.log('open_new_tab');
                window.open(this.href, '_blank');
            } else {
                // Open in the same tab
                this.$router.push(this.routeObject);
            }
        },
    },
};
</script>

<style>
</style>
