export const State = () => {
    return {
        cities: [],
        materials: [],
        // @TODO rename to ordered_flat_materials
        sort_order_materials: [],

        selected_cities: [],
        selected_materials: [],
        material_names: {},
        city_names: {},
    };
};

export const Mutations = () => {
    return {
        sort_materials(state, payload) {
            // Flat material list
            let ids = [];
            const cb = (e) => {
                ids.push(e.id);
                e.children && e.children.forEach(cb);
            };
            state.materials.forEach(cb);
            state.sort_order_materials = ids;
        },
    };
};

export const Actions = () => {
    return {
        fetchCities({ dispatch, commit, state }, payload) {
            if (state.cities.length) {
                return Promise.resolve();
            }
            const default_params = {
                where: [
                    ["template", "=", 9],
                    ["published", "!=", 0],
                    ["deleted", "!=", 1],
                ],
                select: [
                    "id",
                    "alias",
                    "template",
                    "menutitle",
                    "published",
                    "deleted",
                    "menuindex",
                ],
            };
            return dispatch(
                "ajax_basic",
                {
                    data: Object.assign(default_params, payload),
                    url: "/api/modx_resource/get_resources",
                },
                { root: true }
            ).then((response) => {
                commit("toState", { cities: response.data.props });
            });
        },
        fetchMaterials({ dispatch, commit, state, rootGetters }, payload) {
            const progress_key = "common__fetchMaterials";
            if (state.materials.length && state.sort_order_materials.length) {
                return Promise.resolve();
            }
            if (rootGetters.in_progress(progress_key)) {
                return Promise.resolve();
            }
            return dispatch(
                "ajax_basic",
                {
                    data: {},
                    progress_key,
                    url: "/api/modx_resource/get_materials_for_tree",
                },
                { root: true }
            ).then((response) => {
                commit("toState", { materials: response.data.props });
                commit("sort_materials");
            });
        },
        getMaterialAndCityNames({ dispatch, commit, state, rootGetters }) {
            const progress_key = "getMaterialAndCityNames";
            if (rootGetters.is_loaded(progress_key)) {
                return Promise.resolve();
            }
            return dispatch(
                "ajax_basic",
                {
                    url: "/api/modx_resource/get_material_and_city_names",
                    progress_key,
                    cb: (response) => {
                        commit("toState", {
                            material_names: response.data.props.material_names,
                            city_names: response.data.props.city_names,
                        });
                    },
                },
                { root: true }
            );
        },
    };
};

export const Getters = () => {
    return {
        getMaterialName: (state) => (key) => {
            return state.material_names[key];
        },
        getMaterialById: (state) => (id) => {
            function findMaterial(materials, targetId) {
                for (const material of materials) {
                    if (material.id === targetId) return material;
                    if (material.children && material.children.length) {
                        const found = findMaterial(material.children, targetId);
                        if (found) return found;
                    }
                }
                return null;
            }
            return findMaterial(state.materials, id);
        },
        getCityName: (state) => (key) => {
            if (state.cities.length) {
                let elem = state.cities.find((city) => city.id === key);
                return elem.menutitle;
            } else if (state.city_names[key]) {
                return state.city_names[key];
            } else {
                return "...";
            }
        },
    };
};
