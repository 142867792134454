import VueRouter from "vue-router";

import index_few_symbols from "./components/index_few_symbols";
import index_all_groups from "./components/index_all_groups";
import symbol_page from "./components/symbol_page";

import store from "./store";

let dev_routes = [
    {
        path: "/team/api-metals",
        component: index_few_symbols,
        name: "index_few_symbols",
    },
    {
        path: "/team/api-metals/rates",
        component: index_all_groups,
        name: "index_all_groups",
    },
    {
        path: "/team/api-metals/:code",
        component: symbol_page,
        name: "symbol_page",
    },
];
let prod_routes = [
    { path: "/", component: index_few_symbols, name: "index_few_symbols" },
    { path: "/rates", component: index_all_groups, name: "index_all_groups" },
    {
        path: "/rates/:code",
        component: symbol_page,
        name: "symbol_page",
    },
];
const router = new VueRouter({
    mode: "history",
    base: "/",
    routes: process.env.NODE_ENV === "development" ? dev_routes : prod_routes,
});
router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    if (to.name === "symbol_page") {
        store.dispatch("fetch_symbol_history", to.params.code).then(() => {
            next();
        });
    } else {
        next();
    }
});
export default router;
