<template>
    <div class="container symbol_page">
        <div v-if="main_symbol_code.length">
            <router-link :to="{ name: 'index_all_groups' }"> Назад к списку </router-link>
            <div class="row">
                <div class="col-12 col-md-7 col-lg-5 col-xl-4 col-xxl-4">
                    <div class="table_wr">
                        <div class="subtitle_date">Цена на {{ elem.current.day }}</div>
                        <table class="table">
                            <SymbolPageRow
                                :symbol="symbol_latest"
                                :convert_to__currency_symbol="convert_to__currency_symbol"
                                :convert_to__currency_code="convert_to__currency_code"
                                :convert_to__currency_rate="convert_to__currency_rate"
                                :usd_to_rub_rate="latest_usd_to_rub_rate"
                            />
                        </table>
                    </div>

                    <ul class="info_block" v-if="!is_converted_cur_equal_to_base">
                        <li>Цена конвертирована по курсу ЦБ РФ</li>
                    </ul>
                </div>
                <div class="col-12 col-md-5 col-lg-7 col-xl-8 col-xxl-8 hidden-xs">
                    <div class="switch_cur">
                        <ul class="switch_cur_list">
                            <li
                                :class="{
                                    is_active: convert_to__currency_code === 'RUB',
                                }"
                            >
                                <button
                                    class="btn m-1 btn-sm"
                                    :class="{
                                        'btn-primary': convert_to__currency_code === 'RUB',
                                        'btn-outline-secondary':
                                            convert_to__currency_code !== 'RUB',
                                    }"
                                    @click="set_currency('RUB')"
                                >
                                    RUB
                                </button>
                                <span>Российский рубль</span>
                            </li>
                            <li
                                v-for="symbol in currency_all_cbr"
                                :key="symbol.id"
                                :class="{
                                    is_active: convert_to__currency_code === symbol.code,
                                }"
                            >
                                <button
                                    class="btn m-1 btn-sm"
                                    :class="{
                                        'btn-primary': convert_to__currency_code === symbol.code,
                                        'btn-outline-secondary':
                                            convert_to__currency_code !== symbol.code,
                                    }"
                                    @click="set_currency(symbol.code)"
                                >
                                    {{ symbol.code }}
                                </button>
                                <span>{{ symbol.name_ru }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- <h2 class="chart_title">Динамика цены на {{ symbol_name }}</h2> -->
            <div class="chart_block_wr">
                <ChartForSymbolPage :chartData="chartData" class="chart_block" />
            </div>

            <div class="mt-4 mb-4">
                <AllGroups />
            </div>
        </div>
    </div>
</template>

<script>
import AllGroups from './all_groups.vue';
import EmPrice from './em_price.vue';
import SymbolPageRow from './symbol_page_row.vue';
import ChartForSymbolPage from './chart_for_symbol_page.vue';
import { mapGetters, mapState } from 'vuex';
export default {
    components: {
        SymbolPageRow,
        ChartForSymbolPage,
        EmPrice,
        AllGroups,
    },
    data() {
        return {
            units: ['oz', 'toz', 'g', 'kg', 'mt'],
            //show_in_unit: 'g',
            main_symbol_code: '',
            convert_to__currency_code: 'USD',
            additional_on_chart_symbol_codes: [],
            colors: [
                '3498db',
                'e74c3c',
                '2ecc71',
                'f39c12',
                '9b59b6',
                '1abc9c',
                'e67e22',
                '34495e',
                'd35400',
                '27ae60',
            ],
        };
    },
    mounted() {
        this.main_symbol_code = this.$route.params.code;
        //this.show_in_unit = this.symbol.unit;
        this.convert_to__currency_code = this.get_base_cur();
        const h1 = document.querySelector('.main_title');
        if (h1) {
            h1.textContent = this.symbol_name + ' — биржевой курс';
        }
        const elementToRemove = document.querySelector('.loading_symbol_page_data');
        if (elementToRemove) {
            elementToRemove.remove();
        }
        const rates_introtext = document.querySelector('.rates_introtext');
        if (rates_introtext) {
            rates_introtext.style.display = 'none';
        }
    },
    computed: {
        ...mapState([
            'all_metals_latest',
            'symbols',
            'symbols_history',
            'groups',
            'latest_cbr_rates',
            'related_symbol_codes',
        ]),
        ...mapGetters(['can', 'in_progress', 'in_progress_class', 'is_loaded']),
        symbol() {
            return this.symbols.find((el) => el.code == this.main_symbol_code);
        },
        is_converted_cur_equal_to_base() {
            return this.get_base_cur() === this.convert_to__currency_code;
        },
        show_in_unit() {
            return this.symbol.visible_unit;
        },
        symbol_name() {
            return this.symbol.name_ru || this.symbol.name;
        },
        all_metals_latest_symbols() {
            return this.all_metals_latest.map((el) => {
                let symbol = this.symbols.find((sy) => sy.id === el.symbol_id);
                el.symbol_code = symbol.code;
                el.symbol_title = symbol.name_ru || symbol.name || symbol.code;
                return el;
            });
        },
        symbol_history() {
            return this.$store.state.symbols_history[this.main_symbol_code] || [];
        },
        elem() {
            return this.all_metals_latest.find((el) => el.symbol_id == this.symbol.id);
        },
        related_to_this_symbol_codes() {
            const group = this.related_symbol_codes.find((gr) => gr.all.includes(this.symbol.code));
            if (group) {
                return group;
            } else {
                return {
                    primary: [this.symbol.code],
                    all: [this.symbol.code],
                    hidden_units: [],
                };
            }
        },
        symbol_latest() {
            const metal = this.all_metals_latest.find((m) => m.symbol_id === this.symbol.id);
            if (!metal) {
                return false;
            }
            return {
                em_prices: metal.current.em_prices,
                cur: metal.current.cur,
                hidden_units: this.groups.find((gr) => gr.symbols.includes(this.symbol.code))
                    .hidden_units,
            };
        },
        related_to_this_symbol_latest_rates() {
            const codes = this.related_to_this_symbol_codes.all;
            console.log(this.all_metals_latest);
            const symbols = this.symbols
                .filter((sy) => codes.includes(sy.code))
                .map((el) => {
                    let metal = this.all_metals_latest.find((m) => m.symbol_id === el.id);
                    if (!metal) {
                        return false;
                    }
                    el.em_prices = metal.current.em_prices;
                    el.cur = metal.current.cur;
                    el.hidden_units = this.groups.find((gr) =>
                        gr.symbols.includes(el.code)
                    ).hidden_units;
                    return el;
                })
                .filter((el) => el !== false);
            return symbols;
        },
        latest_base_price() {
            return this.elem.em_prices;
        },
        latest_usd_to_rub_rate() {
            return this.latest_cbr_rates.find((el) => el.sy_id == this.convert_to__USD_symbol.id)
                .price;
        },
        latest_currency_date() {
            let code = this.convert_to__currency_code;
            if (code === 'RUB') {
                code = 'USD';
            }
            return this.latest_cbr_rates.find(
                (el) => el.sy_id == this.symbols.find((sy) => sy.code == code).id
            ).date;
        },
        convert_to__currency_rate() {
            //const currency;
            let currency_to_rub_price;
            if (this.convert_to__currency_code === 'RUB') {
                currency_to_rub_price = 1;
            } else {
                currency_to_rub_price = this.latest_cbr_rates.find(
                    (el) => el.sy_id == this.convert_to__currency_symbol.id
                ).price;
            }
            return this.latest_usd_to_rub_rate / currency_to_rub_price;
        },
        latest_currency_updated_price() {
            let updated_price = {};
            Object.keys(this.elem.em_prices).forEach((k) => {
                updated_price[k] = this.elem.em_prices[k] * this.convert_to__currency_rate;
            });
            return updated_price;
        },
        hidden_units() {
            return this.groups.find((gr) => gr.symbols.includes(this.symbol.code)).hidden_units;
        },
        convert_to__USD_symbol() {
            return this.symbols.find((el) => el.code == 'USD');
        },
        convert_to__currency_symbol() {
            return this.symbols.find((el) => el.code == this.convert_to__currency_code);
        },
        currency_all_cbr() {
            // Определяем, какие валюты предатсвлены для всех дней.
            // Для этого сперва проходимся по не пустым дням, считаем кол-во каждой валюты
            // Потом определяем какой валюты больше всего (столько же, сколько и не пустых дней)
            // Отметаем валюты, которые встречаются реже и выводим отфильтрованные валюты
            let currs = [];

            let not_empty_dats = this.symbols_history[this.main_symbol_code].currs_in_rub.filter(
                (el) => el.rates.length
            );

            not_empty_dats.forEach((el) => {
                el.rates.forEach((r) => {
                    let index = currs.findIndex((cu) => cu.sy_id == r.sy_id);
                    if (index !== -1) {
                        currs[index].count++;
                    } else {
                        currs.push({ sy_id: r.sy_id, count: 1 });
                    }
                });
            });
            const max_count = currs.reduce(
                (max, obj) => (obj.count > max.count ? obj : max),
                currs[0].count
            );
            if (not_empty_dats.length === max_count) {
                let currs_ids = currs.filter((el) => el.count === max_count).map((el) => el.sy_id);
                return this.symbols.filter((sy) => currs_ids.includes(sy.id));
            } else {
                return [];
            }
        },
        chartData() {
            let labels = [];
            let data = [];
            const main_symbol_base_cur = this.symbol_latest.cur === 1 ? 'USD' : 'RUB';
            console.log(this.symbol);
            this.symbols_history[this.main_symbol_code].materials_in_usd.forEach((el) => {
                labels.push(el.day);
                // let price = this.convert_price_for_historical(
                //     el.rates.find((r) => r.sy_id == this.symbol.id).em_prices[this.show_in_unit],
                //     el.date,
                //     main_symbol_base_cur
                // );
                let price = el.rates.find((r) => r.sy_id == this.symbol.id).em_prices[
                    this.show_in_unit
                ];
                if (!price) {
                    price = NaN;
                }
                data.push(price);
            });
            let main_dataset = {
                label: this.symbol_name + ' (' + main_symbol_base_cur + ')',
                backgroundColor: '#0d6efd',
                data: data,
                cubicInterpolationMode: 'monotone',
                fill: false,
                borderColor: '#0d6efd',
            };
            let additional_datasets = [];
            this.additional_on_chart_symbol_codes.forEach((code, i) => {
                let symbol = this.symbols.find((sy) => sy.code == code);
                let symbol_name = symbol.name_ru || symbol.name || symbol.code;

                const symbol_base_cur = symbol.cur === 1 ? 'USD' : 'RUB';
                let history_data = [];
                this.symbols_history[code].materials_in_usd.forEach((el) => {
                    let price = el.rates.find((r) => r.sy_id == symbol.id).em_prices[
                        this.show_in_unit
                    ];
                    if (!price) {
                        price = NaN;
                    }
                    history_data.push(price);
                });

                additional_datasets.push({
                    label: symbol_name + ' (' + symbol_base_cur + ')',
                    backgroundColor: '#' + this.colors[i],
                    data: history_data,
                    cubicInterpolationMode: 'monotone',
                    fill: false,
                    borderColor: '#' + this.colors[i],
                });
            });
            return {
                labels,
                datasets: [main_dataset, ...additional_datasets],
            };
        },
    },
    methods: {
        get_base_cur() {
            const cur = this.all_metals_latest.find((el) => el.code == this.$route.params.code)
                .current.cur;
            if (cur === 1) {
                return 'USD';
            }
            if (cur === 2) {
                return 'RUB';
            }
        },
        symbol_on_chart_toggle(symbol_code) {
            if (this.additional_on_chart_symbol_codes.includes(symbol_code)) {
                this.additional_on_chart_symbol_codes =
                    this.additional_on_chart_symbol_codes.filter((el) => el != symbol_code);
            } else {
                this.$store.dispatch('fetch_symbol_history', symbol_code).then(() => {
                    this.additional_on_chart_symbol_codes.push(symbol_code);
                });
            }
        },
        set_currency(code) {
            // validate
            if (this.symbols.find((el) => el.code == this.convert_to__currency_code)) {
                this.convert_to__currency_code = code;
            } else {
                console.log('Не найден ' + code + ' в symbols');
            }
        },
        convert_price_for_historical(price, date, source_currency_code) {
            if (source_currency_code == this.convert_to__currency_code) {
                return price;
            } else {
                // Вроде стало работать норм, но запишу в чем была сложность. С появлением второй базовой валюты (рубля), стало трудно манипулировать кастылями при конвертации. И валюты вели себя странно, но вроде получилось закрыть все дыры. Но почему-то как будто не загружаются все даты с CBR, то есть не на все даты есть данные, и это странно.
                // Похоже, это связанно с тем, что не подгружаются именно курсы вылют
                // Да, для разных символов подгружаются по-разному даты. Похоже, что не загружаются даты без значений. почему нет значений пока не понятно
                // Вероятно, это означает, что нужно строить график четко по дням, и пофиг - какие данные есть, такие и размещаем
                let currency_rates_on_date = this.get_date_for_historical_convertaion(date);
                if (!currency_rates_on_date) {
                    return NaN;
                }

                let usd_to_rub_price = this.currency_rate_to_rub(
                    // ~92
                    currency_rates_on_date,
                    this.convert_to__USD_symbol.id
                ).price;

                let currency_to_rub_price = 1; // отношение к рублю

                // Если мы конвертируем из рубля,
                if (this.convert_to__currency_code !== 'RUB') {
                    // console.log(currency_rates_on_date);
                    currency_to_rub_price = this.currency_rate_to_rub(
                        currency_rates_on_date,
                        this.convert_to__currency_symbol.id
                    ).price;
                }
                if (source_currency_code === 'RUB' && this.convert_to__currency_code === 'USD') {
                    return price / usd_to_rub_price;
                }

                return price * (usd_to_rub_price / currency_to_rub_price);
            }
        },
        get_date_for_historical_convertaion(date) {
            let currency_rates_on_date = this.symbol_history.currs_in_rub.find((el) => {
                // Дата с курсами, или дата, с последними доступными курсами (по reflect_date_to)
                return (el.rates.length && el.date === date) || el.reflect_date_to.includes(date);
            });
            if (!currency_rates_on_date) {
                console.warn('currency_rates_on_date не найден для ' + date);
                return false;
            }
            return currency_rates_on_date;
        },
        currency_rate_to_rub(currency_rates_on_date, symbol_id) {
            let currency = currency_rates_on_date.rates.find((el) => el.sy_id === symbol_id);
            if (!currency) {
                console.warn('currency не найден для sy_id ' + this.convert_to__currency_symbol.id);
                return { price: 0 };
            }
            return currency;
        },
    },
};
</script>

<style>
</style>
