var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container symbol_page"},[(_vm.main_symbol_code.length)?_c('div',[_c('router-link',{attrs:{"to":{ name: 'index_all_groups' }}},[_vm._v(" Назад к списку ")]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-7 col-lg-5 col-xl-4 col-xxl-4"},[_c('div',{staticClass:"table_wr"},[_c('div',{staticClass:"subtitle_date"},[_vm._v("Цена на "+_vm._s(_vm.elem.current.day))]),_vm._v(" "),_c('table',{staticClass:"table"},[_c('SymbolPageRow',{attrs:{"symbol":_vm.symbol_latest,"convert_to__currency_symbol":_vm.convert_to__currency_symbol,"convert_to__currency_code":_vm.convert_to__currency_code,"convert_to__currency_rate":_vm.convert_to__currency_rate,"usd_to_rub_rate":_vm.latest_usd_to_rub_rate}})],1)]),_vm._v(" "),(!_vm.is_converted_cur_equal_to_base)?_c('ul',{staticClass:"info_block"},[_c('li',[_vm._v("Цена конвертирована по курсу ЦБ РФ")])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-5 col-lg-7 col-xl-8 col-xxl-8 hidden-xs"},[_c('div',{staticClass:"switch_cur"},[_c('ul',{staticClass:"switch_cur_list"},[_c('li',{class:{
                                is_active: _vm.convert_to__currency_code === 'RUB',
                            }},[_c('button',{staticClass:"btn m-1 btn-sm",class:{
                                    'btn-primary': _vm.convert_to__currency_code === 'RUB',
                                    'btn-outline-secondary':
                                        _vm.convert_to__currency_code !== 'RUB',
                                },on:{"click":function($event){return _vm.set_currency('RUB')}}},[_vm._v("\n                                RUB\n                            ")]),_vm._v(" "),_c('span',[_vm._v("Российский рубль")])]),_vm._v(" "),_vm._l((_vm.currency_all_cbr),function(symbol){return _c('li',{key:symbol.id,class:{
                                is_active: _vm.convert_to__currency_code === symbol.code,
                            }},[_c('button',{staticClass:"btn m-1 btn-sm",class:{
                                    'btn-primary': _vm.convert_to__currency_code === symbol.code,
                                    'btn-outline-secondary':
                                        _vm.convert_to__currency_code !== symbol.code,
                                },on:{"click":function($event){return _vm.set_currency(symbol.code)}}},[_vm._v("\n                                "+_vm._s(symbol.code)+"\n                            ")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(symbol.name_ru))])])})],2)])])]),_vm._v(" "),_c('div',{staticClass:"chart_block_wr"},[_c('ChartForSymbolPage',{staticClass:"chart_block",attrs:{"chartData":_vm.chartData}})],1),_vm._v(" "),_c('div',{staticClass:"mt-4 mb-4"},[_c('AllGroups')],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }