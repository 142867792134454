import { indexOf } from "lodash";

const appendToState = (state, payload) => {
    // for (const key in payload) {
    //     state[key].push(payload[key]);
    // }


    let buildKeys = function (obj, keys, value) {
        let current_key = keys.shift();
        if (keys.length === 0) {
            //console.log(current_key);
            obj[current_key].push(value);
        } else {
            if (typeof obj[current_key] !== "object") {
                obj[current_key] = {};
            }
            buildKeys(obj[current_key], keys, value);
        }
    };

    for (const key in payload) {
        if (key.indexOf(",") !== -1) {
            console.warn(`appendToState has ${key}, must be dot "." instead of comma ","`);
        }
        let keys = key.split(".");
        //console.log(key, keys);
        buildKeys(state, keys, payload[key]);
    }
};
export default appendToState;
