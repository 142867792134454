<template>
  <div v-if="is_loaded('init_fetch')">
        <h2 class="rates_grid_title">Драгоценные металлы</h2>
        <div class="rates_grid row">
            <GridItem
                v-for="elem in symbols_of_group(0)"
                :key="elem.symbol_id"
                :elem="elem"
                :active_group="groups[0]"
            />
        </div>
        <h2 class="rates_grid_title">Базовые металлы</h2>
        <div class="rates_grid row">
            <GridItem
                v-for="elem in symbols_of_group(1)"
                :key="elem.symbol_id"
                :elem="elem"
                :active_group="groups[1]"
            />
        </div>
        <h2 class="rates_grid_title">Редкие и др. металлы</h2>
        <div class="rates_grid row">
            <GridItem
                v-for="elem in symbols_of_group(2)"
                :key="elem.symbol_id"
                :elem="elem"
                :active_group="groups[2]"
            />
        </div>
  </div>
</template>

<script>
import GridItem from './grid_item.vue';
import { mapGetters, mapState } from 'vuex';
export default {
    components: {
        GridItem,
    },
    computed: {
        ...mapState(['all_metals_latest', 'symbols', 'groups']),
        ...mapGetters(['can', 'in_progress', 'in_progress_class', 'is_loaded']),
    },
    mounted() {
        this.$store.dispatch('init_fetch');
    },
    methods: {
        symbols_of_group(i) {
            const symbols = this.groups[i].symbols
                .map((s) => {
                    let symbol = this.symbols.find((el) => el.code === s);
                    if (symbol) {
                        return this.all_metals_latest.find((m) => m.symbol_id === symbol.id);
                    } else {
                        return false;
                    }
                })
                .filter((el) => !!el);
            return symbols;
        },

    },
}
</script>

<style>

</style>
