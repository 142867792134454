<template>
    <div class="container">

        <AllGroups />
    </div>
</template>

<script>
import AllGroups from './all_groups.vue';
export default {
    components: {
        AllGroups,
    },
    mounted() {
        const h1 = document.querySelector('.main_title');
        if (h1) {
            h1.textContent = 'Биржевые котировки';
        }

        const elementToRemove = document.querySelector('.loading_symbol_page_data');
        if (elementToRemove) {
            elementToRemove.remove();
        }
        const rates_introtext = document.querySelector('.rates_introtext');
        if (rates_introtext) {
            rates_introtext.style.display = 'block';
        }
    },
    data() {
        return {
            active_tab_key: 'precious',
        };
    },
};
</script>

<style>
</style>
