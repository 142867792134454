<template>
    <div class="container">
        <div v-if="is_loaded('init_fetch')">
            <div class="rates_grid row">
                <GridItem
                    v-for="elem in symbols_to_show"
                    :key="elem.symbol_id"
                    :elem="elem"
                    :open_new_tab="true"
                />
            </div>
        </div>
    </div>
</template>

<script>
import GridItem from './grid_item.vue';
import { mapGetters, mapState } from 'vuex';
export default {
    components: {
        GridItem,
    },
    data(){
        return {
            symbol_codes: [
                'XAU_cbr', // Золото
                'XAG_cbr', // серебро
                'XPT_cbr', // Платина
                'XPD_cbr', // Палладий
                'XCU', // Медь
                'ALU', // Алюминий
                'LEAD', // Свинец
                'TIN', // Олово
                'MO', // Молибден
                'NI', // Никель
                'ND', // Неодим
                'GALLIUM', // Галлий
            ]
        }
    },
    computed: {
        ...mapState(['all_metals_latest', 'symbols', 'groups']),
        ...mapGetters(['can', 'in_progress', 'in_progress_class', 'is_loaded']),
        symbols_to_show(){
            const symbols = this.symbol_codes
                .map((code) => {
                    let symbol = this.symbols.find((el) => el.code === code);
                    if (symbol) {
                        return this.all_metals_latest.find((m) => m.symbol_id === symbol.id);
                    } else {
                        return false;
                    }
                })
                .filter((el) => !!el);
            return symbols;
        },
    },
    mounted() {
        this.$store.dispatch('init_fetch');
    },
};
</script>

<style>
</style>
