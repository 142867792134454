// in index. need to be:
// import Notifications from 'vue-notification';
// Vue.use(Notifications);

export default {
    methods: {
        notify: function (props) {
            let defaultProps = {
                group: "default-top-center",
                type: "warn", // success /  warn / error / info
                // title: '',
                // text: '',
                // duration: 3000,
                speed: 500,
            };
            switch (props.type) {
                case "success":
                    defaultProps.duration = 10000;
                    break;
                case "warn":
                    defaultProps.duration = 10000;
                    break;
                case "error":
                    defaultProps.duration = 10000;
                    break;
            }
            return this.$notify(Object.assign(defaultProps, props));
        },
    },
};
