import axios from "axios";

import Vue from "vue";
import Notifications from "vue-notification";

Vue.use(Notifications);

const httpClient = ({ data, url, method, headers, show_notify_errors }) => {
    const start = performance.now();
    method = method || "post";
    headers = headers || {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
    };
    return axios({
        method,
        url,
        data,
        headers,
    })
        .then((response) => {
            if (typeof response.data !== "object") {
                return Promise.reject(response);
            }
            const end = performance.now();
            const executionTime = (end - start) / 1000;
            console.group(
                response.config.method.toUpperCase(),
                response.status,
                response.config.url,
                executionTime.toFixed(3)
            );
            let props = response.data.props || response.data;
            console.log(props);
            console.groupEnd();
            return response;
        })
        .then((response) => {
            let props = response.data.props || response.data;
            if (typeof props.notify !== "undefined") {
                Vue.notify(
                    Object.assign(
                        {
                            group: "default-top-center",
                            speed: 500,
                            duration: 6000,
                            type: "success",
                        },
                        props.notify
                    )
                );
            }
            return response;
        })
        .catch((error) => {
            let err;
            if (typeof error.response !== "undefined") {
                err = error.response;
            } else {
                err = error;
            }
            if (
                err.status == 401 &&
                err.request.responseURL.indexOf(window.location.origin) === 0
            ) {
                return window.location.reload();
            }

            console.group("Axios catch error");
            if (typeof err !== "undefined") {
                console.warn(err.data);
                if (show_notify_errors) {
                    let error_data;
                    if (typeof err.data.props !== "undefined") {
                        error_data = err.data.props;
                    } else {
                        error_data = err.data;
                    }

                    let text = error_data.message;

                    if (error_data.errors) {
                        text += "<br>";
                        for (let i in error_data.errors) {
                            text += error_data.errors[i].join(" ") + "<br>";
                        }
                    }
                    Vue.notify({
                        group: "default-top-center",
                        type: "error",
                        title: "Ошибка :/",
                        text: text,
                        duration: 6000,
                        speed: 500,
                    });
                }
            }
            console.groupEnd();
            throw error;
        });
};

export default httpClient;
