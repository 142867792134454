export const Mutations = () => {
    return {
        set_loaded(state, payload) {
            let obj = {};
            if (!Array.isArray(payload)) {
                payload = [payload];
            }
            payload.forEach((v) => {
                obj[v] = true;
            });
            state.loaded.events = { ...state.loaded.events, ...obj };
        },
        unset_loaded(state, payload) {
            let obj = {};
            if (!Array.isArray(payload)) {
                payload = [payload];
            }
            payload.forEach((v) => {
                obj[v] = false;
            });
            state.loaded.events = { ...state.loaded.events, ...obj };
        },
        set_progress(state, payload) {
            let obj = {};
            if (!Array.isArray(payload)) {
                payload = [payload];
            }
            payload.forEach((v) => {
                obj[v] = true;
            });
            state.progress.events = { ...state.progress.events, ...obj };
        },
        unset_progress(state, payload) {
            let obj = {};
            if (!Array.isArray(payload)) {
                payload = [payload];
            }
            payload.forEach((v) => {
                obj[v] = false;
            });
            state.progress.events = { ...state.progress.events, ...obj };
        },
        clear_progress(state, payload) {
            state.progress.events = {};
        },
        clear_loaded(state, payload) {
            state.loaded.events = {};
        },
    };
};
