window.Vue = require("vue");
import Vue from "vue";
import VueRouter from "vue-router";
import router from "./router";
import Notifications from "vue-notification";
import App from "@Js/components/App";
//import VModal from 'vue-js-modal';
Vue.use(VueRouter);
Vue.use(Notifications);
//Vue.use(VModal, { dialog: true });

import mixin__notify from "@Js/mixins/mixin__notify";
Vue.mixin(mixin__notify);
import mixin__ajax_basic from "@Js/mixins/mixin__ajax_basic";
Vue.mixin(mixin__ajax_basic);

import store from "./store";

// store.dispatch("fetchUserPermission").then(() => {
    const app = new Vue({
        el: "#app",
        render: (r) => r(App),
        router,
        store,
        methods: {},
    });
// });
