import httpClient from "@Js/httpClient";
import toState from "./Mutation_toState";
import appendToState from "./Mutation_appendToState";

import {
    State as Material_City_State,
    Mutations as Material_City_Mutations,
    Actions as Material_City_Actions,
    Getters as Material_City_Getters,
} from "@Js/mixins/vuexMaterial_City";

import { Mutations as Mutation_load_and_progress } from "@Js/mixins/Mutation_load_and_progress";

export const State = () => {
    return {
        ...Material_City_State(),
        progress: {
            events: {},
            class: "progress-bar-animated progress-bar-striped",
        },
        loaded: {
            events: {},
        },
        auth: {
            user: {},
            permissions: [],
        },
        not_rus_cities: [
            18899, 18901, 35785, 45388, 65429, 81643, 81644, 82617, 82618,
            82619, 82620, 82621, 82624, 85447, 89635, 89634, 89637, 89636,
            91281, 88441, 89633, 35786,
        ],
    };
};

export const Mutations = () => {
    return {
        ...Material_City_Mutations(),
        ...Mutation_load_and_progress(),

        toState,
        appendToState,
        // Убрать эти мутации, но надо убедиться, что больше нигде они не используются..
        setAuthUser(state, payload) {
            state.auth.user = payload;
        },
        setAuthPermissions(state, payload) {
            state.auth.permissions = payload;
        },
    };
};

export const Actions = () => {
    return {
        ...Material_City_Actions(),
        before_request: function ({ commit }, keys) {
            commit("unset_loaded", keys);
            commit("set_progress", keys);
        },
        after_request: function ({ commit }, keys) {
            commit("set_loaded", keys);
            commit("unset_progress", keys);
        },
        ajax_basic: function (
            { dispatch, commit, state, getters },
            { data, url, method, headers, progress_key, cb, show_notify_errors }
        ) {
            if (typeof show_notify_errors === "undefined") {
                show_notify_errors = true;
            }
            if (!progress_key) {
                progress_key = method + ":" + url;
            }

            if (getters.in_progress(progress_key)) {
                // Это вызывает ошибку в консоли, так как reject не обработан. Но.. может и окей?.. Эта ошибка ведь по факту ни к чему плохому не ведет..
                return Promise.reject("ALREADY IN PROGRESS");
            }
            dispatch("before_request", progress_key);

            return httpClient({
                data,
                url,
                method,
                headers,
                show_notify_errors,
            })
                .then((response) => {
                    if (typeof response.data.auth.user !== "undefined") {
                        commit("setAuthUser", response.data.auth.user);
                    }
                    if (typeof response.data.auth.permissions !== "undefined") {
                        commit(
                            "setAuthPermissions",
                            response.data.auth.permissions
                        );
                    }
                    if (cb) {
                        // Такую конструкцию пришлось сделать чтобы сокритить немного код в модулях, но при этом гарантировать чтобы выполнение "конструктивного" кода выполнялось перед тем, как укажем, что результат получен и выполнение запроса завершено.
                        // При этом, если колбэйк не задан, то можно делать все по стандарту и указывать операции через .then()
                        cb(response);
                    }
                    return response;
                })
                .finally(() => {
                    //if (progress_key) {
                    dispatch("after_request", progress_key);
                    //}
                });
        },
        fetchUserPermission({ dispatch, commit, state }) {
            return dispatch("ajax_basic", {
                url: "/api/user/get_permissions",
            });
        },
    };
};
export const Getters = () => {
    return {
        ...Material_City_Getters(),
        is_loaded: (state) => (key) => {
            return !!state.loaded.events[key];
        },
        in_progress: (state) => (key) => {
            return !!state.progress.events[key];
        },
        in_progress_class: (state) => (key) => {
            if (state.progress.events[key]) {
                return state.progress.class;
            } else {
                return "";
            }
        },

        getAny: (state) => (key) => {
            let buildKeys = function (obj, keys, value) {
                let curren_key = keys.shift();
                if (keys.length === 0) {
                    return obj[curren_key];
                } else {
                    return buildKeys(obj[curren_key], keys, value);
                }
            };
            let keys = key.split(".");
            return buildKeys(state, keys);
        },
        can: (state) => (permission) => {
            return state.auth.permissions.indexOf(permission) !== -1;
        },
    };
};
