<template>
    <tbody>
        <EmPrice
            v-for="unit in units"
            :key="unit"
            :base_unit="symbol.unit"
            :price="price(unit)"
            :unit="unit"
            :hidden_units="symbol.hidden_units"
            :currency_code="currency_code"
        />
    </tbody>
</template>

<script>
import EmPrice from './em_price.vue';
export default {
    components: {
        EmPrice,
    },
    props: [
        'symbol',
        'convert_to__currency_symbol',
        'convert_to__currency_code',
        'convert_to__currency_rate',
        'usd_to_rub_rate',
    ],
    data() {
        return {
            units: [ 'g', 'oz', 'toz', 'kg', 'mt'],
        };
    },
    computed: {
        currency_code() {
            return this.convert_to__currency_symbol.code;
        },
    },
    methods: {
        price(unit) {
            if (this.symbol.cur === 2) {
                if (this.convert_to__currency_symbol.code === 'USD') {
                    return this.symbol.em_prices[unit] / this.usd_to_rub_rate;
                } else {
                    return (
                        (this.symbol.em_prices[unit] * this.convert_to__currency_rate) /
                        this.usd_to_rub_rate
                    );
                }
            }

            return this.symbol.em_prices[unit] * this.convert_to__currency_rate;
        },
    },
};
</script>

<style>
</style>
