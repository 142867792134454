<template>
    <div>
        <notifications position="top center" group="default-top-center" />
        <!-- <v-dialog /> -->
        <router-view :key="$route.fullPath"></router-view>
    </div>
</template>
<script>
export default {
    mounted() {
        //console.log('App Mounted...');
    },
};
</script>
<style lang="scss">

</style>
