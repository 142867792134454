<template>
    <tr v-if="price_visible !== 0">
        <td class="price_value">
            <div v-if="price_visible !== 0">{{ price_visible }}</div>
        </td>
        <td class="em_tip">{{ ru_za }}</td>
    </tr>
</template>

<script>
export default {
    props: {
        price: {
            type: Number,
        },
        base_unit: {
            type: String,
        },
        unit: {
            type: String,
        },
        hidden_units: {
            type: Array,
            default: function () {
                return [];
            },
        },
        currency_code: {
            type: String,
            default: 'USD',
        },
    },
    data() {
        return {
            units_ru: [
                { em: 'g', za: 'за грамм' },
                { em: 'oz', za: 'за унцию' },
                { em: 'toz', za: 'за тройскую унцию' },
                { em: 'kg', za: 'за килограмм' },
                { em: 'mt', za: 'за тонну' },
            ],
        };
    },
    computed: {
        price_visible() {
            if (this.hidden_units.includes(this.unit) || this.price < 0.01) {
                return 0;
            } else {
                return this.price.toLocaleString('ru-RU', {
                    style: 'currency',
                    currency: this.currency_code,
                    //minimumFractionDigits: 0,
                });
            }
        },
        is_default_unit() {
            return false;
            return this.unit === this.base_unit;
        },
        ru_za() {
            return this.units_ru.find((item) => item.em === this.unit).za;
        },
    },
};
</script>
