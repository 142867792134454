<template>
    <LineChartGenerator
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
    />
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy';

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement);

export default {
    name: 'LineChart',
    components: {
        LineChartGenerator,
    },
    props: {
        chartId: {
            type: String,
            default: 'line-chart',
        },
        datasetIdKey: {
            type: String,
            default: 'label',
        },
        width: {
            type: Number,
            default: 400,
        },
        height: {
            type: Number,
            default: 400,
        },
        cssClasses: {
            default: '',
            type: String,
        },
        styles: {
            type: Object,
            default: () => {},
        },
        plugins: {
            type: Array,
            default: () => [],
        },
        chartData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            // chartData: {
            //     labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],

            //     datasets: [
            //         {
            //             label: 'Data One',
            //             backgroundColor: '#0969da',
            //             data: [40, 39, 10, NaN, 39, 80, 40],
            //             cubicInterpolationMode: 'monotone',
            //             fill: false,
            //             borderColor: '#ff6384',
            //         },
            //     ],
            // },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false, // Hide legend
                    },
                },
            },
        };
    },
};
</script>
