const toState = (state, payload) => {
    let buildKeys = function (obj, keys, value) {
        let curren_key = keys.shift();
        if (keys.length === 0) {
            // console.log(curren_key, obj[curren_key]);
            obj[curren_key] = value;
        } else {
            if (typeof obj[curren_key] !== "object") {
                obj[curren_key] = {};
            }
            buildKeys(obj[curren_key], keys, value);
        }
    };
    for (const key in payload) {
        const keys = key.split(".");
        buildKeys(state, keys, payload[key]);
    }
};

export default toState;
